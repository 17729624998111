<template>
  <div class="tc-control_questionnaire_result">
    <!-- 头部 -->
    <div class="header-tool">
      <div class="interviewBtn" @click="showAddTesPaper">
        <img
          src="../../../assets/images/addExaminationBtn.png"
          height="40"
          alt
        />
      </div>
      <div class="del" @click="del">
        <img src="../../../assets/images/del.png" alt />
      </div>
      <div class="refresh" @click="refresh()">
        <img src="../../../assets/images/refresh.png" alt />
      </div>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border
        class="tableBox"
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="试卷名称"
          width="500"
          align="left"
          header-align="center"
        ></el-table-column>
        <el-table-column prop="qtid" label="分类" width="200" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.qtid | filterClass }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="plain" @click="goSelect(scope.row)"
              >挑题</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="goPrint(scope.row)"
              >打印</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="goLook(scope.row)"
              >查看问卷题目</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              size="mini"
              type="plain"
              @click="showAddTesPaper(scope.row)"
              >修改</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="del(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 页码 -->
    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="1"
        :page-size="pageSize"
        :page-sizes="[15, 30, 50, 100, 200, 400]"
        layout="total,sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- 添加试卷模态框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="addTesPaper"
      width="50%"
      center
    >
      <el-form
        ref="form1"
        :rules="rules"
        :model="form"
        label-width="165px"
        size="mini"
      >
        <el-row>
          <el-col :span="11">
            <el-form-item label="所属名称:" prop="qtid">
              <el-select v-model="form.qtid" placeholder="请选择所属分类">
                <el-option
                  :label="item.title"
                  :value="item.id"
                  v-for="(item, key) in classData"
                  :key="key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="试卷名称:" prop="title">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="是否自动获得的分数:" prop="automatic">
              <el-radio-group v-model="form.automatic">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="13">
            <el-form-item label="答题时间限制:" prop="limit">
              <el-input v-model="form.limit"></el-input>
              <span style="margin-left: 20px">(单位：分钟)</span>
            </el-form-item>
            <el-form-item label="试卷时间范围:" prop="date">
              <el-date-picker
                v-model="form.date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="timestamp"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="是否共享:" prop="share">
              <el-radio-group v-model="form.share">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="选择随机试题类别:" v-if="isState == 1">
          <el-select v-model="form.region" placeholder="请选择所属分类">
            <el-option
              :label="item.title"
              :value="item.id"
              v-for="(item, key) in classData"
              :key="key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="从题库中随机抽题:" v-if="isState == 1">
          <table>
            <tr>
              <td>单选题:</td>
              <td>
                共
                <span> <el-input v-model="form.types['1'][0]"></el-input> </span
                >题
              </td>
              <td>
                共
                <el-input v-model="form.types['1'][1]"></el-input>分
              </td>
            </tr>
            <tr>
              <td>多选题:</td>
              <td>
                共
                <span> <el-input v-model="form.types['2'][0]"></el-input> </span
                >题
              </td>
              <td>
                共
                <el-input v-model="form.types['2'][1]"></el-input>分
              </td>
            </tr>
            <tr>
              <td>判断题:</td>
              <td>
                共
                <span> <el-input v-model="form.types['3'][0]"></el-input> </span
                >题
              </td>
              <td>
                共
                <el-input v-model="form.types['3'][1]"></el-input>分
              </td>
            </tr>
            <tr>
              <td>填空题:</td>
              <td>
                共
                <span> <el-input v-model="form.types['4'][0]"></el-input> </span
                >题
              </td>
              <td>
                共
                <el-input v-model="form.types['4'][1]"></el-input>分
              </td>
            </tr>
            <tr>
              <td>排序题:</td>
              <td>
                共
                <span> <el-input v-model="form.types['5'][0]"></el-input> </span
                >题
              </td>
              <td>
                共
                <el-input v-model="form.types['5'][1]"></el-input>分
              </td>
            </tr>
            <tr>
              <td>计算题:</td>
              <td>
                共
                <span> <el-input v-model="form.types['6'][0]"></el-input> </span
                >题
              </td>
              <td>
                共
                <el-input v-model="form.types['6'][1]"></el-input>分
              </td>
            </tr>
            <tr>
              <td>简答题:</td>
              <td>
                共
                <span> <el-input v-model="form.types['7'][0]"></el-input> </span
                >题
              </td>
              <td>
                共
                <el-input v-model="form.types['7'][1]"></el-input>分
              </td>
            </tr>
            <tr>
              <td>问答题:</td>
              <td>
                共
                <span> <el-input v-model="form.types['8'][0]"></el-input> </span
                >题
              </td>
              <td>
                共
                <el-input v-model="form.types['8'][1]"></el-input>分
              </td>
            </tr>
            <tr>
              <td>作文题:</td>
              <td>
                共
                <span> <el-input v-model="form.types['9'][0]"></el-input> </span
                >题
              </td>
              <td>
                共
                <el-input v-model="form.types['9'][1]"></el-input>分
              </td>
            </tr>
          </table>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addTesPaper = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
let that;
import {
  getType,
  getQesub,
  deleteQuesub,
  saveQuesub,
} from "@/api/questionnaire.js";
export default {
  inject: ["reload"],
  data() {
    return {
      // ***********检验************
      rules: {
        qtid: [{ required: true, message: "请选择所属分类", trigger: "blur" }],
        title: [{ required: true, message: "请填写试卷名称", trigger: "blur" }],
        automatic: [{ required: true, message: "请选择称谓", trigger: "blur" }],
        limit: [
          { required: true, message: "请填写答题时间限制", trigger: "blur" },
        ],
        date: [
          { required: true, message: "请选择试卷时间范围", trigger: "blur" },
        ],
        share: [{ required: true, message: "请选择此项", trigger: "blur" }],
      },
      // **************************
      multipleSelection: [],
      form: {
        date: [],
        qtid: "",
        limit: "",
        title: "",
        share: "",
        automatic: "",
        region: "",
        stime: "",
        etime: "",
        types: {
          1: [],
          2: [],
          3: [],
          4: [],
          5: [],
          6: [],
          7: [],
          8: [],
          9: [],
        },
      },
      date: [],
      tableData: [],
      addTesPaper: false,
      id: 0,
      // 页码
      page: 0,
      pageSize: 15,
      total: 0,
      classData: [],
      isState: 1,
      title: "添加试卷",
    };
  },
  created() {
    this.loadListData();
    this.getClass();
  },
  methods: {
    // 刷新
    refresh() {
      this.reload();
    },
    // 页码
    handleCurrentChange(val) {
      this.page = val * 1 - 1;
      this.loadListData();
    },
    // 显示添加、修改模态框
    showAddTesPaper(val) {
      this.reset();
      this.addTesPaper = true;
      this.title = "添加试卷";
      this.isState = 1;
      if (val.id) {
        this.isState = 0;
        let { automatic, etime, stime, limit, qtid, share, title, id } = val;
        this.title = "修改试卷信息";
        this.form.automatic = automatic;
        this.form.limit = limit;
        this.form.qtid = qtid;
        this.form.share = share;
        this.form.title = title;
        this.form.date[0] = stime * 1000;
        this.form.date[1] = etime * 1000;
        this.id = id;
      }
    },
    // 加载列表数据
    loadListData() {
      let data = {
        page: this.page,
        pagesize: this.pageSize,
      };
      getQesub(data).then((res) => {
        this.tableData = res.data;
        this.total = res.num;
      });
    },
    // 获取试题分类
    getClass() {
      let data = {
        page: 0,
        pagesize: 999999,
      };
      // console.log("数据",data);
      // return
      getType(data).then((res) => {
        this.classData = res.data;
      });
    },
    // 添加 / 修改
    add() {
      //***********校验************
      let isCode = true; //控制是否表单验证通过
      this.$refs.form1.validate((valid) => {
        if (!valid) {
          this.$message({
            showClose: true,
            message: "温馨提醒，还有内容未填写",
            type: "warning",
          });
          isCode = false;
          return;
        }
      });
      //控制是是否结束请求
      if (!isCode) {
        return;
      }

      // 时间数据处理
      this.form.stime = this.form.date[0] / 1000;
      this.form.etime = this.form.date[1] / 1000;
      // 数据提取
      let {
        qtid,
        limit,
        title,
        share,
        automatic,
        region,
        stime,
        types,
        etime,
      } = this.form;
      let data = {
        qtid,
        limit,
        title,
        share,
        automatic,
        region,
        stime,
        etime,
        types: JSON.stringify(types),
      };
      // 修改数据处理
      if (this.isState != 1) {
        data.id = this.id;
        delete data.types;
        delete data.region;
      }
      // 发送请求
      saveQuesub(data).then((res) => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "success",
          });

          this.loadListData();
          this.addTesPaper = false;
        }
      });
    },
    // 重置
    reset() {
      this.form.date = [];
      this.form.qtid = "";
      this.form.limit = "";
      this.form.title = "";
      this.form.share = "";
      this.form.automatic = "";
      this.form.region = "";
      this.form.stime = "";
      this.form.etime = "";
      this.form.types = {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
      };
    },
    // 全选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let p = [];
      this.multipleSelection.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection = p;
      // console.log(p);
    },
    // 删除
    del(row) {
      let ids = "";
      // 数据处理
      if (row.id) {
        ids = row.id;
      } else {
        if (this.multipleSelection.length <= 0) {
          this.$message({
            showClose: true,
            message: "请先勾选要删除的问卷",
            type: "warning",
          });
          return;
        } else {
          ids = this.multipleSelection.toString();
        }
      }
      this.$confirm("此操作将永久删除该问卷, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteQuesub({ ids: ids }).then((res) => {
            if (res.code == 400200) {
              this.page = 0;
              this.$message({
                showClose: true,
                message: "删除问卷成功！",
                type: "success",
              });
              this.loadListData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 转跳选择题目页面
    goSelect(row) {
      this.$router.push(
        `/control_questionnaire_result/questionnaire_select?id=${row.id}&title=${row.title}`
      );
    },
    // 转跳用户查看题目
    goLook(row) {
      this.$router.push(
        `/control_questionnaire_result/questionnaire_look?id=${row.id}&title=${row.title}`
      );
    },
    // 新打开-打印预览
    goPrint(row) {
      let routeData = this.$router.resolve({
        path: "/questionnaire_print",
        query: { id: row.id },
      });
      window.open(routeData.href, "_blank");
    },
  },
  beforeCreate: function () {
    that = this;
  },
  filters: {
    filterClass(val) {
      let tmp = "";
      that.classData.forEach((v) => {
        if (v.id == val) {
          tmp = v.title;
        }
      });
      return tmp;
    },
  },
};
</script>

<style lang="less">
.tc-control_questionnaire_result {
  // 顶部功能
  .header-tool {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .del {
      width: 100px;
      margin-right: 20px;
      cursor: pointer;
    }
    .refresh {
      width: 100px;
      cursor: pointer;
    }
    .interviewBtn {
      width: 130px;
      margin-right: 20px;
      cursor: pointer;
    }
    img {
      width: 100%;
      pointer-events: none;
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 755px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  // 模态框
  .el-dialog__wrapper {
    //   表格
    .content {
      position: relative;
      // height: calc(~"100% - 220px");
      min-height: 500px;
      // margin-top: 20px;
      color: rgb(4, 87, 102);
      table {
        border: none;
      }
      th {
        color: rgb(4, 87, 102);
        padding: 0;
        height: 40px;
        line-height: 40px;
        background: rgb(217, 242, 247);
      }
      td {
        padding: 0;
        height: 35px;
        line-height: 35px;
      }
      tr.el-table__row--striped td {
        background: rgb(234, 246, 248);
      }
      .el-button + .el-button {
        margin-left: 0px;
      }
      .el-button--mini {
        padding: 4px 4px;
        background: transparent;
        color: rgb(4, 87, 102);
        font-size: 14px;
        &:hover {
          border: 1px solid rgb(105, 105, 105);
        }
      }
      .no {
        color: green;
      }
      .off {
        color: red;
      }
      .el-input__inner {
        width: 50px;
        padding: 5px;
        text-align: center;
      }
    }
    .el-input,
    .el-input--mini {
      width: 200px;
    }
    table {
      border: 1px solid #cccccc;
      box-sizing: border-box;
      tr {
        padding: 5px 0;
      }
      width: 50%;
      text-align: center;
      .el-input,
      .el-input__inner {
        width: 50px;
      }
    }
  }
  // 页码
  .page {
    display: flex;
    justify-content: flex-end;
  }
}
</style>